import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';



// Ваши настройки Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBN7mpyK-bLcoD_iSYlVBvMtBsrOH1jD6Q",
  authDomain: "balance-ddb48.firebaseapp.com",
  databaseURL: "https://balance-ddb48-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "balance-ddb48",
  storageBucket: "balance-ddb48.appspot.com",
  messagingSenderId: "106483280088",
  appId: "1:106483280088:ios:928050cdfb9c82d1e7cfad",
};

// Инициализация Firebase
initializeApp(firebaseConfig);



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


