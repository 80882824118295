import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FiHome, FiUser, FiMail, FiMenu } from 'react-icons/fi';
import Header from './Header/Header';
import HomePage from './HomePage/HomePage';
import AboutPage from './AboutPage/AboutPage';
import ContactPage from './ContactPage/ContactPage';
import AuthPage from './AuthPage/AuthPage'; // Предполагается, что компонент AuthPage создан
import './Header/Header.css';
import './App.css';

function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleLogin = (isLoggedIn) => {
    setIsUserLoggedIn(isLoggedIn);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div className="app-container">
        {isUserLoggedIn ? (
          <>
            <div className={`sidebar ${isMenuOpen ? 'open' : 'closed'}`}>
              <ul>
                <li><Link to="/"><FiHome /> {isMenuOpen && <span>Главная</span>}</Link></li>
                <li><Link to="/about"><FiUser /> {isMenuOpen && <span>О нас</span>}</Link></li>
                <li><Link to="/contact"><FiMail /> {isMenuOpen && <span>Контакты</span>}</Link></li>
              </ul>
            </div>
            <div className="content">
              <button className="menu-toggle" onClick={toggleMenu}><FiMenu /></button>
              <div className="page-content">
                <Header />
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                </Routes>
              </div>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="*" element={<AuthPage onLogin={handleLogin} />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
