import React from 'react';

function AboutPage() {
  return (
    <div>
      <h1>О нас</h1>
      <p>Здесь информация о вашей компании или проекте.</p>
      {/* Дополнительное содержание */}
    </div>
  );
}

export default AboutPage;
