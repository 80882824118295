import React from 'react';

function HomePage() {
  return (
    <div>
      <h1>Главная Страница</h1>
      <p>Добро пожаловать на наш сайт!</p>
      {/* Дополнительное содержание */}
    </div>
  );
}

export default HomePage;
