// AuthPage.js
import React, { useState } from 'react';

function AuthPage({ onLogin }) {
  const [isLoginView, setIsLoginView] = useState(true); // Переключение между входом и регистрацией
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState(''); // Только для регистрации

  const toggleView = () => setIsLoginView(!isLoginView);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(isLoginView ? 'Вход' : 'Регистрация', email, password);

    // Здесь должен быть API вызов для регистрации или входа

    // Для примера, предположим, что операция всегда успешна
    onLogin(true);
  };

  return (
    <div className="auth-page">
      <h2>{isLoginView ? 'Вход' : 'Регистрация'}</h2>
      <form onSubmit={handleSubmit}>
        {!isLoginView && (
          <div className="form-group">
            <label htmlFor="username">Имя пользователя</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required={!isLoginView}
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Пароль</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">{isLoginView ? 'Войти' : 'Зарегистрироваться'}</button>
      </form>
      <button onClick={toggleView}>
        {isLoginView ? 'Нет аккаунта? Зарегистрируйтесь' : 'Уже есть аккаунт? Войдите'}
      </button>
    </div>
  );
}

export default AuthPage;
