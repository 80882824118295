import React from 'react';

function ContactPage() {
  return (
    <div>
      <h1>Контакты</h1>
      <p>Свяжитесь с нами по следующим контактам...</p>
      {/* Дополнительное содержание */}
    </div>
  );
}

export default ContactPage;
